import { Translate } from "./type";

export const Italian:Translate = {
    'email is a required field': "L'email è un campo obbligatorio",
    'password is a required field': "La password è un campo obbligatorio",
    'copy address': 'Copia indirizzo',
    'Workshop and Schuhlungen': 'Workshop e formazione',
    kontakt: 'contatto',
    partner: 'partner',
    geschichte: 'storia',
    team: 'team',
    'uber uns': 'su di noi',
    'workshop & schuhlungen': 'workshop e corsi di formazione',
    '3D druck': 'stampante 3D',
    maschinenservice: 'assistenza tecnica',
    kataloge: 'cataloghi',
    angebot: 'offerta',
    geschlossen: 'chiuso',
    uhr: 'orologio',
    'samstag und sonntag': 'sabato e domenica',
    freitag: 'venerdì',
    Telefondienst: 'orari di apertura',
    'montag - donnerstag': 'lunedì - giovedì',
    impressum: 'impronta',
    'schuh - und orthopädiebedarf': 'calzature e ortopedia',
    'rudolf roth ag': 'rudolf roth ag',
    'anmelden oder login beantragen': 'richiedi accesso o accedi',
    logout: 'logout',
    webshop: 'negozio online',
    schuhlungen: 'corsi di formazione per calzature',
    'unser team': 'il nostro team',
    'mein konto': 'il mio account',
    'erledigte bestellungen': 'ordini completati',
    'pendente bestellungen': 'ordini in sospeso',
    rückgaben: 'resi',
    'defekt melden': 'segnala un difetto',
    accountangaben: 'informazioni sull\'account',
    'änderungen speichern': 'salva modifiche',
    rechnungsadresse: 'indirizzo di fatturazione',
    'plz': 'CAP',
    'ein land': 'un paese',
    'die stadt': 'la città',
    adresse: 'indirizzo',
    kundennummer: 'numero cliente',
    email: 'email',
    'kennwort ändern': 'modifica password',
    'neue adresse hinzufügen': 'aggiungi nuovo indirizzo',
    kontodetails: 'dettagli dell\'account',
    firma: 'azienda',
    kontaktperson: 'persona di contatto',
    telefonnummer: 'numero di telefono',
    'zusatzliche telefonnummer': 'numero di telefono aggiuntivo',
    'gln-nummer': 'numero GLN',
    'mwst-nummer': 'partita IVA',
    lieferadresse: 'lieferadresse',
    'bestellung wiederholen': "ripetere l'ordine",
    bezahlstatus: "stato del pagamento",
    gesamtbetrag: "importo totale",
    size: "taglia",
    colour: "colore",
    'melden sie sich in ihrem konto an': 'si prega di accedere al proprio account',
    email_login: 'email',
    'email oder Kundennummer': 'email o numero cliente',
    password: 'password',
    'nicht registriert?': 'non registrato?',
    'ein Konto erstellen': 'crea un account',
    'passwort vergessen?': 'hai dimenticato la password?',
    'leer': 'vuoto',
    'wird geladen': 'È in corso il caricamento',
    "willkürlich": "arbitrario",
    "festgelegt": "definito",
    "min length 1": "lunghezza minima 1",
    'bilder hochladen': 'immagini elevate',
    'bestellnummer': 'bestellnummer',
    'defektbeschreibung': 'descrizione del difetto',
    delete: 'delete',
    'in den warenkorb': 'aggiungi al carrello',
    'wonach suchst du?': 'che cosa stai cercando?',
    category: 'categoria',
    'leerer warenkorb': 'carrello della spesa vuoto',
    'zurück zum einkaufen': 'torniamo a fare acquisti',
    bestellen: 'per ordinare',
    'zum warenkorb hinzufügen': 'aggiungi al carrello',
    'menge auf lager':'importo in magazzino',
    favoritinnen: 'preferiti',
    kasse: 'kasse',
    versandart: 'versandart',
    lieferung: 'lieferung',
    'Lieferadresse wählen': 'Scegli indirizzo di consegna',
    'Andere Adresse': 'Altro indirizzo', 
    rezension: 'Recensione',
    zahlung: 'Pagamento',
    "We’re on it!": "Ci stiamo occupando!",
    'Your payment has been received and you\'ll get notifications for your order\'s state.': 'Il pagamento è stato ricevuto e riceverai notifiche sullo stato del tuo ordine.',
    'Zurück zum Einkaufen': 'Torna agli acquisti',
    'Something went wrong': 'Qualcosa è andato storto',
    'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.': 'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.',
    "versand": "Spedizione",
    "post": "Codice postale",
    "warenkorb": "Carrello",
    "Total": "Totale",
    "List": "Lista",
    "Price": "Prezzo",
    "Quantity": "Quantità",
    "Total Price": "Prezzo totale",
    "color": "Colore",
    "Rechnung erhalten per": "Ricevere la fattura tramite",
    "Versandart": "Modalità di spedizione",
    "List of products": "Lista dei prodotti",
    "Photo": "Foto",
    "Warenkorb": "Carrello",
    'Unsere Partner': 'Unsere Partner',
    'Schulungen': 'Schulungen',
    Kataloge: 'Cataloghi',
    Gummi: 'gomma',
    Orthopädie: 'ortopedia',
    "Leder": "pelle",
    "Chemie": "chimica",
    "Beschläge": "ferramenta",
    "Schuhpflege": "cura delle scarpe",
    "Werkzeuge": "attrezzi",
    "Maschinen": "macchine",
    Hauptkatalog: 'catalogo principale',
    Firma: "Titolo",
    vorname: "Nome",
    "Treten sie mit uns in kontakt": "Contattaci",
    nachname: "Cognome",
    strasse: "Via",
    'nr.': "N°",
    'nr': "N°",
    postcode: "CAP",
    ort: "Città",
    "Maschinenservicek": "Servizio di assistenza macchine",
    "Ein Maschinenservice-Anforderungsformular": "Modulo di richiesta di assistenza macchine",
    "name": "nome",
    "maschinenbezeichnung": "denominazione macchina",
    "Maschinennummer": "numero macchina",
    "Problem Beschreibung": "descrizione del problema",
    "Maschinen service": "servizio macchine",
    "Workshop and Schulungen": 'Workshop e corsi',
    phone: "Telefono",
    "Sie uns Ihr anliege": "Il tuo messaggio",
    "Rudolf Roth AG": "Rudolf Roth AG",
    "Schuh - und Orthopädiebedarf": "Articoli per calzature e ortopedia",
    "Montag - Donnerstag": "Lunedì - Giovedì",
    Freitag: "Venerdì",
    "7.45 Uhr- 12.00 Uhr": "7.45 - 12.00",
    "13.00 Uhr - 17.15 Uhr": "13.00 - 17.15",
    "13.00 Uhr - 16.45 Uhr": "13.00 - 16.45",
    "Samstag und Sonntag": "Sabato e Domenica",
    Geschlossen: "Chiuso",
    Senden: "Invia",
    kataloge_1: "Il nostro catalogo principale è composto da 9 parti. Cliccando sull'immagine o sul testo corrispondente, accederete a un file PDF. Questi cataloghi vengono aggiornati continuamente.",
    'Anfrageformular für einen Workshop': 'Modulo di richiesta per un workshop',
    'Anfrageformular für 3D-Druck': 'Modulo di richiesta per una stampa 3D',
    "Geschichte_1": "Rudolf Roth AG a Gwatt-Thun (articoli ortopedici, cura delle scarpe, cura della pelle) è dal 1949 un partner competente per calzolai, laboratori ortopedici, negozi specializzati in calzature e negozi di articoli sportivi, un'azienda familiare.",
    "Geschichte_2": "Come specialista ben noto in tutta la Svizzera, l'azienda distribuisce tutti gli articoli utilizzati nell'industria delle calzature tra cui gomma, ortopedia, pelle, prodotti chimici, ferramenta, parti di ricambio, filati, forniture, utensili, macchine e parti di macchine.",
    "Geschichte_3": "La cura delle relazioni commerciali attraverso il contatto personale con il cliente fa parte della filosofia commerciale seria dell'azienda, insieme alla consegna di prodotti di alta qualità ai clienti entro breve tempo.",
    'Ihr Warenkorb': 'Il Tuo Carrello Acquisti',
    'Nettobetrag': 'Importo Netto',
    'MWST':'I.V.A.',
    'Stärke':'Forza',
    'Shore':'Costa',
    'Maßeinheiten':'Dimensioni',
    'WAITING': 'IN ATTESA',
    'OPENED': 'APERTO',
    'ISSUED': 'EMESSO',
    'CANCELED': 'ANNULLATO',
    'CLOSED': 'CHIUSO',
    'NORMAL': 'NORMALE',
    'COMPLETED': 'COMPLETATO',
    welcomeText: "Benvenuti nella nostra pagina dedicata alla formazione!",
    welcomeDescriptionPart1: "Siamo lieti di offrirvi workshop e corsi su misura, pensati per soddisfare le vostre esigenze specifiche. Che il vostro obiettivo sia ottimizzare l`applicazione degli adesivi, esplorare le opportunità offerte dai moderni materiali di fresatura e costruzione, o apprendere le tecniche per realizzare ortesi stampate in 3D, siete nel posto giusto. Il nostro impegno è fornirvi le conoscenze e le competenze necessarie per utilizzare i nostri prodotti e servizi in modo sicuro e professionale, indipendentemente dal vostro livello di esperienza.",
    welcomeDescriptionPart2: "Collaboriamo spesso con tecnici esperti di Renia, Topy e altri partner, che condividono la loro esperienza direttamente sul campo. Inoltre, proponiamo corsi specifici per il settore delle vendite, con particolare attenzione alla promozione di prodotti per la cura delle calzature e dei negozi sportivi. Se desiderate incrementare il vostro fatturato attraverso le vendite accessorie, possiamo aiutarvi a raggiungere questo obiettivo.",
    welcomeDescriptionPart3: "Per permetterci di soddisfare al meglio le vostre richieste, vi invitiamo a compilare il nostro modulo online. Vi chiediamo di indicare il vostro nome, il nome della vostra azienda (se applicabile), il numero di telefono, l`indirizzo e-mail e l`indirizzo fisico. Nel campo messaggi potete specificare quale tipo di formazione vi interessa o quali argomenti desiderate approfondire. Se siete interessati a ottenere crediti formativi per i corsi, saremo lieti di fornirvi tutte le informazioni necessarie. Non vediamo l`ora di accompagnarvi nel vostro percorso formativo e di aiutarvi a sviluppare le vostre competenze.",
    'Umsatz im Bereich Pflegeprodukte mit einer Verkaufsschulung steigern':'Aumentare le vendite nel settore dei prodotti per la cura con la formazione alla vendita',
    druckTextPart1:'Benvenuto! Offriamo un servizio di stampa 3D per ortesi personalizzate, progettate con precisione e attenzione per adattarsi perfettamente alle Sue esigenze. Per avviare il processo, La invitiamo gentilmente a compilare il modulo disponibile su questa pagina.',
    druckTextPart2: 'Le chiediamo di fornire alcune informazioni di base, come il Suo nome completo, il numero di telefono, l`indirizzo e-mail e il Suo indirizzo. Qualora stesse effettuando la richiesta per conto di un`azienda, La preghiamo di indicarne il nome. ',
    druckTextPart3: ' Nel campo dedicato al messaggio, descriva i dettagli della Sua richiesta, specificando l`uso previsto dell`ortesi o eventuali requisiti particolari che ritiene importanti.',
    druckText: 'Materiali disponibili:',
    druckDescriptionPart1: 'Scelga il materiale più adatto per la Sua ortesi tra le seguenti opzioni:',
    druckDescriptionPart2: 'Leggero, flessibile e durevole - ideale per applicazioni a bassa intensità.',
    druckDescriptionPart3: 'Résistant aux chocs, idéal pour un usage quotidien et garanti non toxique, adapté au contact alimentaire',
    druckDescriptionPart4: 'Elastico, flessibile e resistente all`usura - adatto per componenti dinamici o morbidi.',
    druckDescriptionPart5: ' Renforcé avec des fibres de carbone pour une rigidité et une résistance maximales, adapté aux applications exigeantes.',
    druckDescriptionPart6: 'Si vous disposez déjà d`un fichier STL scanné de l`extrémité concernée, vous pouvez le téléverser directement via le formulaire. Si vous ne possédez pas de scan, indiquez-le dans votre message : nous vous contacterons pour organiser un rendez-vous afin de réaliser un scan.',
    druckDescriptionPart7: 'PETG-Carbon: Rinforzato con fibre di carbonio per massima resistenza e rigidità - ideale per applicazioni ad alta intensità.',
    druckDescriptionPart8: 'PP (Polipropilene):',
    druckDescriptionPart9: 'TPU (Poliuretano termoplastico):',
    druckDescriptionPart10: 'PETG (Polietilene tereftalato glicole):',
    druckDescriptionPart11: 'PETG-Carbon:',
    HARDO: 'HARDO',
    maschinenserviceTextPart1: 'Se riscontrate un problema con la macchina, come un guasto, una parte difettosa o la necessità di un servizio filtro, vi invitiamo a compilare il modulo sottostante. Indicate il nome della macchina, i vostri dati di contatto e fornite una descrizione dettagliata del problema. Se possibile, caricate immagini pertinenti per aiutarci nella valutazione. Il nostro team esaminerà rapidamente la vostra richiesta e vi contatterà prontamente per fissare un appuntamento con uno dei nostri tecnici qualificati.',
    Bestellung: 'Ordine',
    'Auf-lager': 'In magazzino',
    Materialoptionen: 'L`opzione materiale',
    PP: 'PP',
    TPU: 'TPU',
    PETG: 'PETG',
    'PETG-Carbon':'PETG-Carbon',
    Gesamtbestellwert: 'Valore totale dell`ordine',
    'phasing out message': 'Jusqu`à épuisement',
    success: 'Modulo inviato con successo!',
    'success-description' : 'I nostri specialisti ti contatteranno a breve',
    'back_to_shop': 'Ritorna al negozio',
}
