import { Translate } from "./type";

export const French:Translate = {
    'email is a required field': "L'email est un champ obligatoire",
    'password is a required field': "Le mot de passe est un champ obligatoire",
    'copy address': "Copier l'adresse",
    'Workshop and Schuhlungen': 'Atelier et formations',
    kontakt: 'contact',
    partner: 'partenaire',
    geschichte: 'histoire',
    team: 'équipe',
    'uber uns': 'à propos de nous',
    'workshop & schuhlungen': 'atelier et formations',
    '3D druck': 'impression 3D',
    maschinenservice: 'service de machines',
    kataloge: 'catalogues',
    angebot: 'offre',
    geschlossen: 'fermé',
    uhr: 'heure',
    'samstag und sonntag': 'samedi et dimanche',
    freitag: 'vendredi',
    'montag - donnerstag': 'lundi - jeudi',
    impressum: 'mentions légales',
    'schuh - und orthopädiebedarf': 'chaussures et matériel orthopédique',
    'rudolf roth ag': 'rudolf roth ag',
    'anmelden oder login beantragen': 'demander une inscription ou se connecter',
    logout: 'déconnexion',
    webshop: 'boutique en ligne',
    schuhlungen: 'formations en chaussures',
    'unser team': 'notre équipe',
    'mein konto': 'mon compte',
    'erledigte bestellungen': 'commandes traitées',
    'pendente bestellungen': 'commandes en attente',
    rückgaben: 'retours',
    'defekt melden': 'signaler un défaut',
    accountangaben: 'informations du compte',
    'änderungen speichern': 'enregistrer les modifications',
    rechnungsadresse: 'adresse de facturation',
    'plz': 'code postal',
    'ein land': 'un pays',
    'die stadt': 'la ville',
    adresse: 'adresse',
    kundennummer: 'numéro de client',
    email: 'email',
    'kennwort ändern': 'changer de mot de passe',
    'neue adresse hinzufügen': 'ajouter une nouvelle adresse',
    kontodetails: 'détails du compte',
    firma: 'entreprise',
    kontaktperson: 'personne de contact',
    telefonnummer: 'numéro de téléphone',
    'zusatzliche telefonnummer': 'numéro de téléphone supplémentaire',
    'gln-nummer': 'numéro GLN',
    'mwst-nummer': 'numéro de TVA',
    lieferadresse: 'lieferadresse',
    'bestellung wiederholen': "répète l'ordre",
    bezahlstatus: "statut de paiement",
    gesamtbetrag: "montant total",
    size: "taille",
    colour: "couleur",
    'melden sie sich in ihrem konto an': 'veuillez vous connecter à votre compte',
    email_login: 'email',
    'email oder Kundennummer': 'email ou numéro de client',
    password: 'mot de passe',
    'nicht registriert?': 'non enregistré ?',
    'ein Konto erstellen': 'créer un compte',
    'passwort vergessen?': 'mot de passe oublié ?',
    'leer': 'vide',
    'wird geladen': 'est en cours de chargement',
    "willkürlich": "arbitraire",
    "festgelegt": "défini",
    "min length 1": "longueur minimale 1",
    'bilder hochladen': 'images haute change',
    'bestellnummer': 'meilleur numéro',
    'defektbeschreibung': 'description défectueuse',
    delete: 'delete',
    'in den warenkorb': 'ajouter au panier',
    'wonach suchst du?': 'que cherchez-vous?',
    category: 'catégorie',
    'leerer warenkorb': 'panier vide',
    'zurück zum einkaufen': 'retour aux achats',
    bestellen: 'commander',
    'zum warenkorb hinzufügen': 'ajouter au panier',
    'menge auf lager':'montant en stock',
    favoritinnen: 'favoris',
    kasse: 'kasse',
    versandart: 'versandart',
    lieferung: 'lieferung',
    'Lieferadresse wählen': 'Choisir l\'adresse de livraison',
    'Andere Adresse': 'Autre adresse', 
    rezension: 'Critique',
    zahlung: 'Paiement',
    "We’re on it!": "Nous nous en occupons !",
    'Your payment has been received and you\'ll get notifications for your order\'s state.': 'Votre paiement a été reçu et vous recevrez des notifications sur l\'état de votre commande.',
    'Zurück zum Einkaufen': 'Retour aux achats',
    'Something went wrong': 'Quelque chose s\'est mal passé',
    'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.': 'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.',
    "versand": "Expédition",
    "post": "Code postal",
    "warenkorb": "Panier",
    "Total": "Total",
    "List": "Liste",
    "Price": "Prix",
    "Quantity": "Quantité",
    "Total Price": "Prix total",
    "color": "Couleur",
    "Rechnung erhalten per": "Recevoir la facture par",
    "Versandart": "Mode d'expédition",
    "List of products": "Liste des produits",
    "Photo": "Photo",
    "Warenkorb": "Panier",
    'Unsere Partner': 'Unsere Partner',
    'Schulungen': 'Schulungen',
    Kataloge: 'Catalogues',
    Hauptkatalog: 'catalogue principal',
    Gummi: 'caoutchouc',
    Orthopädie: 'orthopédie',
    "Leder": "cuir",
    "Chemie": "chimie",
    "Beschläge": "ferrures",
    "Schuhpflege": "entretien des chaussures",
    "Werkzeuge": "outils",
    "Maschinen": "machines",
    Firma: "Civilité",
    vorname: "Prénom",
    "Treten sie mit uns in kontakt": "Contactez-nous",
    nachname: "Nom de famille",
    strasse: "Rue",
    'nr.': "N°",
    'nr': "N°",
    postcode: "Code postal",
    ort: "Ville",
    phone: "Téléphone",
    "Sie uns Ihr anliege": "Votre message",
    "Rudolf Roth AG": "Rudolf Roth AG",
    "Schuh - und Orthopädiebedarf": "Articles de chaussures et orthopédie",
    "Telefondienst": "Heures d'ouverture",
    "Montag - Donnerstag": "Lundi - Jeudi",
    Freitag: "Vendredi",
    "7.45 Uhr- 12.00 Uhr": "7h30 - 12h00",
    "13.00 Uhr - 17.15 Uhr": "13h00 - 17h00",
    "13.00 Uhr - 16.45 Uhr": "13.00 - 16.45",
    "Samstag und Sonntag": "Samedi et Dimanche",
    Geschlossen: "Fermé",
    Senden: "Envoyer",
    "Maschinenservicek": "Service de maintenance des machines",
    "Ein Maschinenservice-Anforderungsformular": "Formulaire de demande de service de machines",
    "name": "nom",
    "maschinenbezeichnung": "désignation de la machine",
    "Maschinennummer": "numéro de machine",
    "Problem Beschreibung": "description du problème",
    "Maschinen service": "service de machines",
    "Workshop and Schulungen": 'Ateliers et formations',
    kataloge_1: "Notre catalogue principal se compose de 9 parties. En cliquant sur l'image ou le texte correspondant, vous accéderez à un fichier PDF. Ces catalogues sont régulièrement mis à jour.",
    'Anfrageformular für einen Workshop': 'Formulaire de demande pour un atelier',
    'Anfrageformular für 3D-Druck': 'Formulaire de demande pour une impression 3D',
    "Geschichte_1": "La Rudolf Roth AG à Gwatt-Thun (matériel orthopédique, soins des chaussures, entretien du cuir) est depuis 1949 un partenaire compétent pour les cordonniers, les ateliers orthopédiques, les magasins spécialisés en chaussures et les magasins de sport, une entreprise familiale.",
    "Geschichte_2": "En tant que spécialiste bien connu à travers la Suisse, l'entreprise distribue tous les articles utilisés dans l'industrie de la chaussure, y compris le caoutchouc, l'orthopédie, le cuir, les produits chimiques, les accessoires, les pièces de réparation, les fils, les fournitures, les outils, les machines et les pièces de machines.",
    "Geschichte_3": "La gestion des relations commerciales par le contact personnel avec le client fait partie de la philosophie commerciale sérieuse de l'entreprise, ainsi que la livraison de produits de haute qualité aux clients dans un délai court.",
    'Ihr Warenkorb': 'Votre Panier',
    'Nettobetrag': 'Montant Net',
    'MWST':'T.V.A.',
    'Stärke':'Force',
    'Shore':'Rive',
    'Maßeinheiten':'Dimensions',
    'WAITING': 'EN ATTENTE',
    'OPENED': 'OUVERT',
    'ISSUED': 'ÉMIS',
    'CANCELED': 'ANNULÉ',
    'CLOSED': 'FERMÉ',
    'NORMAL': 'NORMAL',
    'COMPLETED': 'COMPLÉTÉ',
    welcomeText: "Bienvenue sur notre page 'Formations'!",
    welcomeDescriptionPart1: "Nous sommes ravis de vous proposer des ateliers et formations sur mesure, conçus pour répondre à vos besoins. Que vous souhaitiez optimiser l`utilisation de vos adhésifs, explorer les possibilités des matériaux modernes de fraisage et de construction, ou apprendre à fabriquer des orthèses imprimées en 3D, vous êtes au bon endroit. Notre mission est de vous transmettre les connaissances et compétences nécessaires pour travailler en toute sécurité et avec expertise sur nos produits et services, quel que soit votre niveau d`expérience.",
    welcomeDescriptionPart2: "Lors de ces formations, des techniciens de Renia, Topy et d`autres partenaires nous rejoignent souvent pour partager leur expertise directement sur place. Nous proposons également des formations spécifiques à la vente, notamment dans le domaine des produits d`entretien pour les magasins de chaussures et d`articles de sport. Si vous souhaitez augmenter vos ventes grâce à des produits complémentaires dans votre boutique, nous avons des solutions adaptées pour vous accompagner.",
    welcomeDescriptionPart3: "Afin de mieux répondre à vos attentes, nous vous invitons à remplir notre formulaire en ligne en indiquant votre nom, le nom éventuel de votre entreprise, votre numéro de téléphone, votre adresse e-mail et votre adresse postale. Utilisez le champ 'Message' pour nous faire part de la formation qui vous intéresse ou des thèmes spécifiques que vous souhaitez approfondir. Si vous êtes intéressé(e) par l`obtention de crédits pour ces formations, nous serons heureux de vous fournir toutes les informations nécessaires.Nous sommes impatients de vous accompagner dans votre parcours d`apprentissage et de développer ensemble votre expertise !",
    'Umsatz im Bereich Pflegeprodukte mit einer Verkaufsschulung steigern':"Augmenter les ventes dans le domaine des produits de soins grâce à une formation à la vente",
    druckTextPart1:'Bienvenue sur notre page dédiée à la demande d`orthèses sur mesure réalisées par impression 3D ! Nous proposons des solutions personnalisées, fabriquées avec précision pour répondre à vos besoins spécifiques. Pour lancer le processus, merci de remplir le formulaire ci-dessous. Cela nous permettra de recueillir toutes les informations nécessaires afin de concevoir une orthèse parfaitement adaptée à vos attentes.',
    druckTextPart2: 'Les informations suivantes sont nécessaires pour traiter votre demande : votre nom complet, votre numéro de téléphone, votre adresse e-mail et votre adresse postale. Si vous soumettez cette demande au nom d`une entreprise, veuillez également inclure le nom de celle-ci.',
    druckTextPart3: 'Dans le champ prévu à cet effet, décrivez vos besoins en détail. Indiquez, par exemple, l`utilisation prévue de l`orthèse ainsi que toute exigence ou contrainte spécifique, comme le design, le confort ou la mobilité.',
    druckText: 'Choix des matériaux :',
    druckDescriptionPart1: 'Sélectionnez parmi les matériaux suivants celui qui convient le mieux à votre orthèse:',
    druckDescriptionPart2: 'Léger, flexible et durable, idéal pour des applications à faible charge.',
    druckDescriptionPart3: 'Résistant aux chocs, idéal pour un usage quotidien et garanti non toxique, adapté au contact alimentaire',
    druckDescriptionPart4: 'Élastique, souple et résistant à l`usure, parfait pour des composants dynamiques.',
    druckDescriptionPart5: 'Renforcé avec des fibres de carbone pour une rigidité et une résistance maximales, adapté aux applications exigeantes.',
    druckDescriptionPart6: 'Se dispone già di un file STL scansionato della parte da trattare, può caricarlo direttamente attraverso il modulo. In caso contrario, La invitiamo a segnalarcelo nel messaggio, e La contatteremo per organizzare una scansione personalizzata.',
    druckDescriptionPart7: 'Une fois votre demande reçue, notre équipe l`étudiera attentivement. Nous vous contacterons rapidement pour discuter des détails et planifier les prochaines étapes. Merci de nous faire confiance pour vos besoins en orthèses sur mesure. Nous avons hâte de collaborer avec vous pour concevoir une orthèse parfaitement adaptée à vos attentes !',
    druckDescriptionPart8: 'PP (Polypropylène):',
    druckDescriptionPart9: 'TPU (Polyuréthane thermoplastique):',
    druckDescriptionPart10: 'PETG (Polyéthylène téréphtalate Glycol):',
    druckDescriptionPart11: 'PETG-Carbone:',
    HARDO: 'HARDO',
    maschinenserviceTextPart1: 'Merci de remplir le formulaire ci-dessous si vous rencontrez un problème avec votre machine, qu`il s`agisse d`une panne, d`une pièce défectueuse ou d`un entretien, comme le remplacement d`un filtre. Veuillez indiquer le nom de la machine, vos coordonnées, ainsi qu`une description détaillée du problème. Si possible, joignez des photos pour nous aider à évaluer la situation. Notre équipe examinera rapidement votre demande et vous contactera pour convenir d`un rendez-vous avec l`un de nos techniciens.',
    Bestellung: 'Commande',
    'Auf-lager': 'En stock',
    Materialoptionen: 'L`option matérielle',
    PP: 'PP',
    TPU: 'TPU',
    PETG: 'PETG',
    'PETG-Carbon':'PETG-Carbon',
    Gesamtbestellwert: 'Valeur totale de la commande',
    'phasing out message': 'Fino a esaurimento',
    success: 'Formulaire soumis avec succès !',
    'success-description' : 'Nos spécialistes vous contacteront sous peu',
    'back_to_shop': 'Retour à la boutique',
};
