import { object, string } from "yup";
import { ILoginGroupSchema } from "./type";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const kundennummerRegex = /^\d{2,}$/;

export const loginGroupSchema = object<ILoginGroupSchema>({
    email: string()
        .required()
        .test(
            (value) => {
                if (!value) return false;
                return emailRegex.test(value) || kundennummerRegex.test(value);
            }
        ),
    password: string().required(),
});
