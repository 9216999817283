import { defineStore } from 'pinia';
import axios from 'axios';
import { useTokenStore } from '../TokenOperationStoreGroup/TokenOperation';
import { StatusStore, ProductType, ProductDetails } from './type';
import { IUniversalDataReturns } from '@/store/type';
import { reactive } from 'vue';
import { usePopupStore } from '@/store/modules/PopupStoreGroup/PopupStore';

const popup = reactive({ name: 'repeat' })

export const StatusGroupStore = defineStore('StatusGroupStore', {
  state: (): StatusStore<ProductType> => ({
    loading: true,
    count: 0,
    next: null,
    previous: null,
    results: [],
  }),
  actions: {
    async getStatusData(status = 1) {
      try {
        this.loading = true;
        const tokenStore = useTokenStore();
        const { data } = await axios.get<IUniversalDataReturns<ProductType>>(process.env.VUE_APP_SERVER + `api/orders/?status=${status}`, {
            headers: { Authorization: `Bearer ${tokenStore.access}` },
            withCredentials: true,
        });
          this.results = data.results;
      } catch (e) {
          console.error(e);
      } finally {
          this.loading = false;
      }
    },
    async repeatOrder(id: number) {
      this.loading = true;
      const tokenStore = useTokenStore();
      const popupStore = usePopupStore(popup.name);
      try {
        await axios.post(
          `${process.env.VUE_APP_SERVER}api/orders/${id}/repeat/`,
          null,
          {
            headers: {
              Authorization: `Bearer ${tokenStore.access}`,
            },
            withCredentials: true,
          }
        );
        return false;
      } catch ({ message }: any) {
        popupStore.message = message as string;
        console.log(message);
        return true;
      } finally {
        this.loading = false;
      }
    },
  },
});


export const OrderDetailsStore = (id: string) => defineStore(`OrderDetailsStore_${id}`, {
  state: (): StatusStore<ProductDetails> => ({
    loading: true,
    count: 0,
    next: null,
    previous: null,
    results: [],
  }),
  actions: {
    async getOrderDetails(orderDetailsId: number) {
      try {
        const tokenStore = useTokenStore();
        const { data } = await axios.get<IUniversalDataReturns<ProductDetails>>(process.env.VUE_APP_SERVER + `api/order_details/?order__id=${orderDetailsId}`, {
                headers: { Authorization: `Bearer ${tokenStore.access}` },
                withCredentials: true,
            });
        this.results = data.results;
      } catch(e) {
          console.log(e)
      }
    }
  },
})();

