import { createI18n } from 'vue-i18n';
import { Deutsch } from './translate/Deutsch';
import { Italian } from './translate/Italian';
import { French } from './translate/French';

const capitalizeFirstLetter = (text: string) => {
    if (!text || typeof text !== 'string') return text;

    const exceptions = ['MwSt', 'MwSt-Nummer'];

    if (exceptions.includes(text)) {
        return text;
    }

    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const formatTranslations = (translations: Record<string, string>) => {
    const formatted: Record<string, string> = {};
    for (const key in translations) {
        formatted[key] = capitalizeFirstLetter(translations[key]);
    }
    return formatted;
};

const Language = {
    DE: formatTranslations(Deutsch),
    IT: formatTranslations(Italian),
    FR: formatTranslations(French),
};

export const LanguageKeys = Object.keys(Language);

export const i18n = createI18n({
    locale: 'DE',
    legacy: false,
    messages: Language,
});
